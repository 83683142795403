<template>
	<section class="error__wrapper">
		<slot></slot>
		<div class="error__message">
			<svg-icon v-if="icon" class="error__icon" icon="warning"></svg-icon>
			<h4 class="is-bold is-size-4" v-html="$t(title)"></h4>
			<p v-html="$t(message)"></p>
		</div>
		<router-link v-if="back" id="button-home-error" class="button is-button is-primary is-rounded" :to="{ name: 'Projects' }">
			{{ $t('buttons.back_to_home') }}
		</router-link>
	</section>
</template>

<script>
import Icon from '@/components/Icon'

export default {
	name: 'Error',
	components: {
		'svg-icon': Icon
	},
    metaInfo () {
      return {
        title: `${this.$tc(this.$route.meta.title)} ${this.number}`
      }
    },
	props: {
		icon: {
			type: Boolean,
			default: false
		},
		number: {
			type: Number,
			default: 404
		},
		title: {
			type: String,
			default: 'error.title'
		},
		message: {
			type: String,
			default: 'error.message'
		},
		back: {
			type: Boolean,
			default: false
		}
	}
}
</script>
